@import "../../../assets/styles/media";

.notifications-alerts-desktop-wrap {
	display: flex;

	@include tablet-down {
		display: none !important;
	}
}

.notifications-alerts {
	$alertIconsSize: 30px;
	cursor: auto !important;

	position: relative;
	width: 30px;

	.bell-animate {
		width: $alertIconsSize;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: 0.3s;
		cursor: pointer;

		&:hover {
			transform: translateY(-50%) scale(1.2);
		}
	}

	.new-notifications {
		display: inline-block;
		width: $alertIconsSize;
		height: $alertIconsSize;
		position: relative;

		&:after {
			content: "";
			width: 8px;
			height: 8px;
			background: var(--violet);
			border-radius: 50%;
			position: absolute;
			top: 5px;
			right: 5px;
		}

		.bell-animate {
			animation-name: bell-ring;
			animation-duration: 100ms;
			animation-iteration-count: 20;
			animation-timing-function: ease-in-out;
			animation-delay: 5s;
			animation-direction: alternate;
			transform-origin: 50%;
		}

		@keyframes bell-ring {
			0% {
				transform: rotate(27deg);
			}
			100% {
				transform: rotate(-27deg);
			}
		}
	}
}

.btn-alerts-wrap {
	margin-right: 20px;
}

.menu-user-profile {
	background: var(--background-color-secondary);
	min-height: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	border-radius: 2px;
	padding: 5px 8px;
	cursor: pointer;
	box-shadow: 0px 5px 10px 0px rgba(0, 24, 32, 0.07);
	transition: 0.25s;

	@include desktop-up {
		&:hover {
			box-shadow:
				0px 2px 4px -1px rgba(0, 0, 0, 0.2),
				0px 4px 5px 0px rgba(0, 0, 0, 0.14),
				0px 1px 10px 0px rgba(0, 0, 0, 0.12);
		}

		&:active {
			box-shadow: 0px 1px 2px 0px rgba(0, 24, 32, 0.24);
		}
	}

	@include tablet-down {
		border-radius: 8px;
	}

	a {
		display: flex;
		align-items: center;
	}

	.company-logo {
		max-height: 26px;
		margin-left: 12px;
	}
}

.user-photo {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-size: auto 100%;
	background-position: center;
}

.user-info {
	margin-left: 7px;

	.user-name {
		display: inline-block;
		max-width: 150px;
	}
}

#menu-mobile,
.menu-mobile-icon {
	display: none;
	width: 100%;

	@include tablet-down {
		display: block;
	}

	.icon-wrap {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #7ff7f8;
		border-radius: 8px;
	}

	.menu-icon {
		font-size: 28px;
		color: #1c1b1f;
	}

	.bell-animate {
		position: static;
	}

	.new-notifications {
		position: relative;
	}

	svg {
		margin-right: 7px;
	}

	.nav-link,
	a {
		color: white;
	}

	.nav-link {
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);

		&:last-of-type {
			border: none;
		}

		a,
		.notification-label,
		&.share-link {
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 22px;
		}
	}
}

.dashboard-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #f8f9f9;
	background: var(--background-color-primary);
	padding: 30px;
	z-index: 1000;
	margin: 0 auto;
	width: 100%;
	display: flex;
	max-width: 1440px;

	@include tablet-down {
		padding: 15px 20px;
	}

	.block-logo {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.btn-back {
		background: rgba(222, 226, 229, 0.65);
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		font-size: 14px;
		color: #7d8c97;
		border: 0;
		border-radius: 22.629px;
		cursor: pointer;
		opacity: 0.65;
		margin: 0 auto 0 15px;
		padding: 4px 16px 4px 12px;

		.icon {
			font-size: 20px;
		}

		.btn-text {
			color: var(--text-color-primary);
			font-size: 14px;
			font-weight: 700;
		}
	}

	.block-btn {
		display: flex;
		align-items: center;

		.header-btn {
			height: 40px;
			margin-right: 5px;
		}

		.share,
		.compare-mobile {
			display: none;

			path {
				stroke: var(--blueDark);
			}
		}
	}

	.nav-page:not(.nav-link-active) {
		position: relative;

		&:after {
			content: "";
			width: 0;
			background: var(--violet);
			height: 3px;
			border-radius: 2px;
			position: absolute;
			left: 50%;
			right: 50%;
			bottom: 5px;
			opacity: 0;
			transition: 0.25s;
		}

		&:hover:after {
			width: 50%;
			left: 25%;
			right: 25%;
			opacity: 1;
			transition: 0.25s;
		}
	}

	.nav-link-active {
		position: relative;

		&.nav-page a {
			color: var(--text-color-primary);
		}

		&:after {
			content: "";
			background: var(--violet);
			height: 3px;
			border-radius: 2px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 5px;
		}
	}

	.nav-link.share-link {
		margin-right: 5px;

		.share-link-icon {
			font-size: 18px;
			margin-right: 8px;
		}
	}
}
