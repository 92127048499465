@import "../../assets/styles/media";

.get-started-steps-container {
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 400px;
	height: 100%;
	flex: 1;
	justify-content: space-between;

	.form-sign-in {
		.title-box {
			justify-content: center;
		}
	}

	&.addition-steps-container {
		min-height: 470px;
	}

	.indicators-dots {
		text-align: center;
		position: absolute;
		top: -40px;
		left: 50%;
		transform: translateX(-50%);

		&.is-email {
			visibility: hidden;
		}

		li {
			display: inline-block;
			width: 8px;
			height: 8px;
			margin: 0 8px;
			background: var(--grayLight);
			border-radius: 50%;

			&.active {
				background: var(--crime);
			}
		}
	}

	.header {
		margin-top: 30px;
		margin-bottom: 20px;
		max-width: 440px;
		margin-left: auto;
		margin-right: auto;

		.title-box {
			display: flex;
			gap: 5px;
		}

		p {
			color: var(--gray);
			margin-top: 13px;
		}

		&.thank {
			.link {
				justify-content: center;
				margin-top: 20px;
			}
		}
	}

	&.block-login {
		.header {
			margin-bottom: 13px;
		}

		h3 {
			margin-bottom: 15px;
		}
	}

	.form-container {
		max-width: 240px;
		width: 100%;
		margin: 0 auto 25px;

		&.form-auth-container {
			margin-top: 44px;
			margin-bottom: 37px;
			min-height: 200px;
		}
	}

	.text-auth {
		color: var(--gray);
		margin-bottom: 38px;
	}

	.btn-auth-container {
		max-width: 250px;
		margin: 0 auto;

		button,
		.button {
			box-shadow: none;
			width: 100%;
			margin-bottom: 10px;
			transition: box-shadow 250ms;

			&:hover {
				opacity: 1 !important;
				box-shadow:
					0px 2px 4px -1px #0003,
					0px 4px 5px 0px #00000024,
					0px 1px 10px 0px #0000001f !important;
			}
		}
	}

	.text-terms {
		font-size: 12px;
		color: var(--gray);
		margin-top: 4px;

		a {
			text-decoration: underline;
		}
	}

	.block-text.line {
		margin-top: 10px;
	}

	.label-skip-login {
		font-size: 13px;
		color: var(--gray);
	}

	.block-btn-skip {
		font-size: 13px;
		text-decoration: underline;
		cursor: pointer;
		margin-top: 3px;
	}

	.field-error {
		font-size: 11px;
		color: red;
	}

	.block-checkbox {
		margin-top: 39px;

		label {
			border-top: 1px solid var(--grayLight);
		}
	}

	&.block-step-final {
		max-width: 440px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		padding-top: 30px;

		img {
			width: 60px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.block-btn {
		&.change-destination {
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include desktop-up {
				flex-direction: row-reverse;
			}

			.btn {
				margin: 20px 10px 0;
			}
		}

		button {
			min-width: 242px;

			@include mobile-down {
				min-width: 220px;
			}
		}
	}

	.fields-container {
		max-width: 245px;
		width: 100%;
		margin: 0 auto;

		&-pos-center {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}

		.name-wrap,
		.email-pass-wrap {
			.field-inner {
				text-align: left;
			}
		}
	}

	.report-limit-wrap {
		margin-top: 30px;

		.text {
			font-size: 12px;
			margin-top: 4px;
		}
	}

	.form-forgot-container {
		margin-top: 50px;
	}
}

.title-wrap {
	display: flex;
	justify-content: center;
	align-items: center;

	.location {
		max-width: 260px;
		width: 100%;
		text-align: left;

		&-home {
			text-align: right;
		}

		.icon {
			margin-right: 10px;
			vertical-align: middle;
		}
	}

	.icon-compare {
		color: var(--gray);
		margin: 0 14px;
	}
}

@media only screen and (max-width: 1400px) {
	.title-wrap {
		flex-direction: column;

		.location {
			max-width: none;
			text-align: center;
		}
	}
}

@media only screen and (max-width: 767px) {
	.get-started-steps-container {
		min-height: auto;
		height: 100%;
		justify-content: space-between;
		overflow-y: auto;

		.indicators-dots {
			top: 0;
		}

		.header {
			margin-top: 20px 0 !important;
			margin-bottom: 20px 0 !important;

			h2 {
				font-size: 18px;
			}

			p {
				font-size: 13px !important;
				margin-top: 0;
			}
		}

		.text-terms {
			margin-top: 0;
		}
	}
}
