@import "../../assets/styles/colors";
@import "../../assets/styles/media";

.profile-personal-data-page {
	display: flex;
	gap: 24.5px;
	margin-top: 20px;

	@include tablet-down {
		margin: 20px 0;
		flex-direction: column;
		align-items: center;
	}

	.col {
		max-width: 439px;
		width: 100%;

		@include desktop-up {
			max-width: 33.33%;
		}
	}

	.col-1 {
		display: flex;
	}

	.user-profile-container,
	.user-personal-data-container,
	.user-report-history-container {
		width: 100%;
		padding: 27px 34px 38px 20px;
		position: relative;

		&:hover {
			.btn-edit {
				color: $blueDark;
				transition: color 0.25s;
			}
		}
	}

	.btn-edit-tip-wrap {
		display: flex;
		align-items: center;
	}

	.btn-edit-tip {
		max-width: 300px;
	}

	.btn-edit-tip-link {
		color: var(--cyanLight);
	}

	.user-report-history-container {
		padding-right: 10px;
		position: relative;
	}

	.btn-edit {
		display: flex;
		align-items: center;
		position: absolute;
		top: 26px;
		right: 38px;
		font-size: 13px;
		color: $gray;
		text-decoration: underline;
		cursor: pointer;
		transition: color 0.25s;

		.edit-icon {
			width: 20px;
			margin-right: 5px;
		}
	}

	.btn-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 39px;

		@include mobile-down {
			display: block;
		}

		.btn-account-delete {
			color: #ff7474;
			cursor: pointer;
			padding: 5px;
			transition: 0.25s;

			&:hover {
				transform: scale(1.05);
			}

			@include mobile-down {
				margin-top: 30px;
			}
		}
	}
}

.modal-personal-btn-cancel {
	max-width: 182px;
	width: 100%;
}
