.form-inner {
	.form-container {
		margin: 0 auto 25px;
		max-width: 240px;
		width: 100%;
	}

	.field-wrap {
		position: relative;

		&.field-wrap-password {
			.field-help-text {
				position: static;
				margin: -16px 0 10px;
			}
		}

		.field-error,
		.field-help-text {
			position: absolute;
			top: 50px;
			font-size: 11px;
			line-height: 1;
			text-align: left;
		}

		.field-error {
			color: red;
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			box-shadow: 0 0 0 30px #fff inset !important;
			-webkit-box-shadow: 0 0 0 30px #fff inset !important;
			-webkit-text-fill-color: var(--blueDark) !important;
		}

		input:-internal-autofill-selected {
			background-color: white !important;
		}
	}

	.field-wrap-link-forgot {
		position: absolute;
		width: 100%;
		margin-top: -15px;
		font-size: 12px;
		color: var(--text-color-secondary);
		text-align: right;
		text-decoration: underline;

		span {
			cursor: pointer;
		}
	}

	.field-wrap-help-password {
		font-size: 11px;
		line-height: 1.2;
	}

	.field-wrap-btn {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 22px;

		button {
			max-width: 240px;
			width: 100%;
			height: 38px;
			letter-spacing: -0.5px;
		}

		.btn-cancel {
			margin-top: 15px;
		}

		.pulse-text {
			transform: scale(1);
			animation: pulse 1s infinite;
		}

		@keyframes pulse {
			0% {
				transform: scale(0.95);
			}

			70% {
				transform: scale(1);
			}

			100% {
				transform: scale(0.95);
			}
		}
	}
}
