@import "../../assets/styles/media";

.profile-preview-wrap {
	width: 100%;
	margin-top: 35px;

	.title {
		margin-top: 17px;
		color: var(--text-color-primary);
	}

	.body {
		margin-top: 10px;
	}

	:global {
		.field-value {
			min-height: 22px;
			color: var(--text-color-primary);
		}

		.field-value-placeholder {
			.help-text,
			.field-value {
				color: var(--grayLight);
			}
		}

		.btn {
			display: flex;
			align-items: center;
			position: absolute;
			top: 50%;
			right: 12px;
			cursor: pointer;
			transform: translateY(-50%);
			opacity: 0;
			transition: opacity 0.25s;

			button {
				border: none;
				background: transparent;
				padding: 0;
				cursor: pointer;
			}

			@include tablet-down {
				opacity: 1;
			}
		}

		.field-inner:hover {
			border-color: transparent;

			.btn {
				opacity: 1;
			}

			.btn-add-disabled {
				opacity: 0.3;
				cursor: auto;
			}
		}
	}

	.avatar-wrap {
		position: relative;
		border-radius: 50%;
		cursor: pointer;

		.avatar {
			width: 110px;
			height: 110px;
			background-size: cover;
			background-position: top;
			border-radius: 50%;
			transition: 0.3s;
		}

		.edit-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 40px;
			opacity: 0;
			transition: opacity 0.2s;
			filter: invert(54%) sepia(14%) saturate(365%) hue-rotate(163deg) brightness(97%) contrast(87%);
		}

		&:hover {
			.avatar {
				border: 1px dashed var(--blueDark);
				opacity: 0.25;
			}

			.edit-icon {
				opacity: 1;
			}
		}
	}
}
