.apple-auth-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 38px;
	background: #000;
	border-width: 2px;
	border-radius: 25px;
	font-size: 1.4rem;
	color: #fff;
	cursor: pointer;
	margin-bottom: 10px;

	.apple-icon {
		width: 16px;
		height: 16px;
		margin: -3px 3px 0 0;
	}
}
