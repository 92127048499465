.profile-personal-data-wrap {
	max-width: 100%;
	margin-top: 35px;

	.title {
		color: var(--text-color-primary);
	}

	.description {
		color: var(--text-color-secondary);
		font-size: 13px;
		line-height: 1.4;
		margin-top: 20px;
	}

	.body {
		margin-top: 10px;
	}

	:global {
		.help-text {
			color: #7d8c97;
			font-size: 1.3rem;
		}

		.field-value {
			color: #233544;
			min-height: 20px;
		}
	}
}
